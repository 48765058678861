import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            /*
            {
                id: 'sample',
                title: 'Sample',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'email',
                url: '/pages/auth/login',
                badge: {
                    title: '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg: '#F44336',
                    fg: '#FFFFFF'
                }
            },
            */
            /*
             {
                 id: 'dashboards',
                 title: 'Dashboards',
                 // translate: 'NAV.DASHBOARDS',
                 type: 'collapsable',
                 icon: 'dashboard',
                 children: [
                     {
                         id: 'analytics',
                         title: 'Analytics',
                         // translate: 'NAV.ANALYTICS',
                         type: 'item',
                         url: '/apps/dashboards/analytics'
                     },
                     {
                         id: 'project',
                         title: 'Reports',
                         // translate: 'NAV.REPORTS',
                         type: 'item',
                         url: '/apps/dashboards/project'
                     }
                 ]
             },
             */
            {
                id: 'scrumboard',
                title: 'Scrumboard',
                // translate: 'NAV.SCRUMBOARD',
                type: 'item',
                icon: 'assessment',
                url: '/apps/scrumboard'
            }
        ]
    }
];
