import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnterpriseService } from './shared/enterprise.service';
import { environment } from 'environments/environment';
import { UserService } from './shared/user.service';
import { Enterprise } from './shared/enterprise';


@Injectable({
    providedIn: 'root'
})
export class AppService {
    private _unsubscribeAll: Subject<any> = new Subject();
    /** constructor */
    constructor(
        private _userService: UserService,
        private _enterpriseService: EnterpriseService,
    ) {
        this._enterpriseService.setCurrent('situaweb')
    }

    /*
    ███████╗██╗   ██╗███████╗███╗   ██╗████████╗███████╗
    ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
    █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   ███████╗
    ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   ╚════██║
    ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   ███████║
    ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝
    */
}
