// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    firebaseConfig: {
        apiKey: "AIzaSyCQEe4s7bMFb-xfxYruH250cOJDdzS7lXw",
        authDomain: "project-manager-c472a.firebaseapp.com",
        projectId: "project-manager-c472a",
        storageBucket: "project-manager-c472a.appspot.com",
        messagingSenderId: "690516841049",
        appId: "1:690516841049:web:1a2947fdad085fd5239748"
    },
    unauthorizedPage: 'pages/auth/login',
    authorizedPage: 'apps/scrumboard/boards',
    installPage: 'pages/install',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
