import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ControlAccesoGuard implements CanActivate, CanActivateChild, CanLoad {
    private _unsubscribeAll = new Subject();
    private _isLoged$;
    private _redirectUnauthorizedTo = null;
    constructor(private _router: Router, private _auth: AngularFireAuth) {
        this._isLoged$ = this._auth.user
            .pipe(
                map(user => user != null),
                takeUntil(this._unsubscribeAll));
        this._isLoged$.subscribe(logged => {
            if (!logged && this._redirectUnauthorizedTo)
                this._router.navigate(this._redirectUnauthorizedTo);
        })
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let data = next.data;
        this._redirectUnauthorizedTo = data.redirectUnauthorizedTo;
        return this._isLoged$;
    }
    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }
}
