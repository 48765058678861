export class User {
    uid: string;
    id: string;
    displayName: string;
    email: string;
    phoneNumber: string;
    photoURL: string;
    providerId: string;
    name: string;
    avatar: string;
    extras: any;
    constructor(user: any) {
        this.uid = user.uid
        this.email = user.email

        this.displayName = user.name || user.displayName || 'Anónimo'
        this.phoneNumber = user.phoneNumber || ''
        this.photoURL = user.avatar || user.photoURL || 'assets/images/avatars/profile.jpg'
        this.providerId = user.providerId || ''

        this.id = this.uid;
        this.name = this.displayName;
        this.avatar = this.photoURL;

        this.extras = user.extras || {};
    }
}
