export class Enterprise {
    uid: string;
    name: string;
    isotipo: string;
    logotipo: string;
    slogan: string;
    url: string;
    contacto: string;
    admin: string;
    roles: { info: string[] }
    constructor(enterprise) {
        this.name = enterprise.name || "";
        this.uid = enterprise.uid || encodeURIComponent(enterprise.name)
            .replace(/%20/g, '-').toLowerCase();
        this.isotipo = enterprise.isotipo || "";
        this.logotipo = enterprise.logotipo || "";
        this.slogan = enterprise.slogan || "";
        this.url = enterprise.url || "";
        this.contacto = enterprise.contacto || "";
        this.admin = enterprise.admin || "";
        this.roles = enterprise.roles || ['owner', 'editor', 'reader'];
    }
}
