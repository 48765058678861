import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enterprise } from './enterprise';

const collection: string = 'enterprises';
const doc: string = 'enterprise';
const id: string = 'uid'
@Injectable({
    providedIn: 'root'
})
export class EnterpriseService implements Resolve<Enterprise> {
    // current enterprise
    private _current$: BehaviorSubject<Enterprise> = new BehaviorSubject<Enterprise>(null)
    public current$: Observable<Enterprise> = this._current$.asObservable();
    public get current(): Enterprise { return this._current$.getValue() }
    public get current_uid(): string { return localStorage.getItem(`${doc}_uid`); }
    /** Constructor */
    constructor(private _firestore: AngularFirestore) {
        let enterprise_uid = localStorage.getItem(`${doc}_uid`);
        this.setCurrent(enterprise_uid);
    }
    /** Resolver */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Enterprise | Observable<Enterprise> | Promise<Enterprise> {
        let uid = route.paramMap.get(`${doc}_uid`)
        return this.get(uid)
    }
    /** Set the current enterprise */
    setCurrent(uid: string) {
        localStorage.setItem(`${doc}_uid`, uid)
        return this._firestore
            .doc<Enterprise>(`${collection}/${uid}`)
            .valueChanges()
            .subscribe(enterprise => this._current$.next(enterprise))
    }

    /*
     ██████╗██╗   ██╗███████╗████████╗ ██████╗ ███╗   ███╗
    ██╔════╝██║   ██║██╔════╝╚══██╔══╝██╔═══██╗████╗ ████║
    ██║     ██║   ██║███████╗   ██║   ██║   ██║██╔████╔██║
    ██║     ██║   ██║╚════██║   ██║   ██║   ██║██║╚██╔╝██║
    ╚██████╗╚██████╔╝███████║   ██║   ╚██████╔╝██║ ╚═╝ ██║
     ╚═════╝ ╚═════╝ ╚══════╝   ╚═╝    ╚═════╝ ╚═╝     ╚═╝
    */

    /**
     * createDefaultEnterprise
     */
    createDefaultEnterprise(): Promise<string> {
        let item = new Enterprise({
            uid: 'default',
            name: 'Empresa'
        });
        return this.create(item);
    }

    /*
    
     ██████╗██████╗ ██╗   ██╗██████╗
    ██╔════╝██╔══██╗██║   ██║██╔══██╗
    ██║     ██████╔╝██║   ██║██║  ██║
    ██║     ██╔══██╗██║   ██║██║  ██║
    ╚██████╗██║  ██║╚██████╔╝██████╔╝
     ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝
    
    */

    /**
     * create
     * @param enterprise 
     */
    create(item: Enterprise): Promise<string> {
        let _item = { ...item }
        return new Promise((resolve, reject) => {
            this._firestore
                .doc(`${collection}/${item.uid}`)
                .set(_item)
                .then(() => {
                    resolve(item[id])
                })
        })
    }

    /**
     * devuelve la enterprise una sola vez
     * @param uid el uid de la enterprise
     */
    get(uid: string): Promise<Enterprise> {
        return this._firestore
            .doc(`${collection}/${uid}`)
            .get()
            .pipe(map(data => data.data() as Enterprise))
            .toPromise()
    }

    /**
     * devuelve un observable del documento
     * @param uid the enterprise uid
     */
    read(uid: string): Observable<Enterprise> {
        return this._firestore
            .doc<Enterprise>(`${collection}/${uid}`)
            .valueChanges()
    }

    /**
     * Actualiza un enterprise
     * @param uid the uid to update
     * @param data the data to update
     */
    update(uid: string, data: any): Promise<void> {
        return this._firestore
            .doc<Enterprise>(`${collection}/${uid}`)
            .update(data)
    }
    /**
     * borra un enterprise
     * @param uid the uid to delete
     */
    delete(uid: string) {
        return this._firestore
            .doc(`${collection}/${uid}`)
            .delete()
    }
}
