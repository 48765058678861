import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import firebase from "firebase/app";

import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { User } from "./user";
import { EnterpriseService } from "./enterprise.service";

const collection: string = "users";
const doc: string = "user";
const id: string = "uid";
@Injectable({
    providedIn: "root",
})
export class UserService implements Resolve<User> {
    private fireUser: firebase.User;
    // current user
    private _current$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    public current$: Observable<User> = this._current$.asObservable();
    public get current(): User {
        return this._current$.getValue();
    }

    /** Constructor */
    constructor(
        private _firestore: AngularFirestore,
        private _auth: AngularFireAuth,
        private _enterpriseService: EnterpriseService
    ) {
        this._auth.user
            .pipe(
                tap((user) => (this.fireUser = user)),
                filter((user) => user != null),
                switchMap((user) =>
                    combineLatest([of(user), this.read(user[id])])
                )
            )
            .subscribe(([_, _user]) => {
                let user = this.fireUser;
                //queda subscrito y el usuario puede haberse deslogeado
                if (!user) return;
                // actualizamos la inforcion del usuario
                let displayName =
                    _user?.name ?? _user?.displayName ?? user.displayName;
                let photoURL =
                    _user?.avatar ?? _user?.photoURL ?? user.photoURL;
                if (displayName != _user?.name || photoURL != _user?.avatar)
                    user.updateProfile({
                        displayName: displayName,
                        photoURL: photoURL,
                    }).then(() => user.reload());
                //actualizamos la empresa actual
                if (
                    this._enterpriseService.current.uid !=
                    _user?.extras?.enterprise
                )
                    this._enterpriseService.setCurrent(
                        _user?.extras?.enterprise
                    );
                //actualizamos los datos para todos
                let item = { ...user, ..._user };
                this.setCurrent(new User(item));
            });
    }
    /** Resolver */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): User | Observable<User> | Promise<User> {
        let uid = route.paramMap.get(`${doc}_${id}`);
        return this.get(uid);
    }
    /** Set the current enterprise */
    setCurrent(user: User) {
        this._current$.next(user);
    }

    /*
     ██████╗██╗   ██╗███████╗████████╗ ██████╗ ███╗   ███╗
    ██╔════╝██║   ██║██╔════╝╚══██╔══╝██╔═══██╗████╗ ████║
    ██║     ██║   ██║███████╗   ██║   ██║   ██║██╔████╔██║
    ██║     ██║   ██║╚════██║   ██║   ██║   ██║██║╚██╔╝██║
    ╚██████╗╚██████╔╝███████║   ██║   ╚██████╔╝██║ ╚═╝ ██║
     ╚═════╝ ╚═════╝ ╚══════╝   ╚═╝    ╚═════╝ ╚═╝     ╚═╝
    */

    /**
     * createDefaultUser
     */
    createDefaultUser(): Promise<void> {
        let item = new User({
            email: "default@app.com",
            displayName: "Default",
        });
        return this.create(item);
    }

    /** Login */
    login(value: any) {
        //nos registramos con google auth
        return this._auth.signInWithEmailAndPassword(
            value.email,
            value.password
        );
    }

    loginWithGoogle() {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        return this._auth.signInWithPopup(provider);
    }

    /** Logout */
    logout() {
        // TODO: Probar esta caracteristica
        this.setCurrent(null);
        return this._auth.signOut();
    }
    /**
     * sirve para registrar a un usuario
     * @param value
     */
    register(value: {
        email: string;
        password: string;
        enterprise: string;
        name: string;
    }) {
        return this._auth
            .createUserWithEmailAndPassword(value.email, value.password)
            .then((newUser) => {
                let user = new User({ ...value, uid: newUser.user.uid });
                user.extras = { enterprise: value.enterprise };
                return this.create(user);
            });
    }
    /**
     * Reload the current user
     */
    reload() {
        console.warn("Este metodo no funciona");
        if (this.fireUser) return this.fireUser.reload();
    }
    updateProfile(data: any) {
        if (!this.fireUser) return;
        let user = new User(data);
        user = { ...user };
        return this.fireUser
            .updateProfile(user)
            .then(() => this.update(this.fireUser.uid, user));
    }
    changePassword(password: string) {
        return new Promise((resolve, reject) => {
            if (!this.fireUser) reject();
            else
                this.fireUser
                    .updatePassword(password)
                    .then(resolve)
                    .catch(reject);
        });
    }
    changeEmail(email: any) {
        return (
            this.fireUser
                .updateEmail(email)
                //actualizamos el correo del usuario
                .then(() => this.update(this.fireUser.uid, { email: email }))
        );
    }
    /*
     ██████╗██████╗ ██╗   ██╗██████╗
    ██╔════╝██╔══██╗██║   ██║██╔══██╗
    ██║     ██████╔╝██║   ██║██║  ██║
    ██║     ██╔══██╗██║   ██║██║  ██║
    ╚██████╗██║  ██║╚██████╔╝██████╔╝
     ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝
    */

    /**
     * create
     * @param item
     */
    create(item: User): Promise<void> {
        let _item = { ...item };
        return this._firestore.doc(`${collection}/${item[id]}`).set(_item);
    }

    /**
     * devuelve la enterprise una sola vez
     * @param uid el uid de la enterprise
     */
    get(uid: string): Promise<User> {
        return this._firestore
            .doc<User>(`${collection}/${uid}`)
            .get()
            .pipe(map((data) => data.data()))
            .toPromise();
    }

    /**
     * devuelve un observable del documento
     * @param uid the enterprise uid
     */
    read(uid: string): Observable<User> {
        return this._firestore.doc<User>(`${collection}/${uid}`).valueChanges();
    }

    /**
     * Actualiza un enterprise
     * @param uid the uid to update
     * @param data the data to update
     */
    update(uid: string, data: any): Promise<void> {
        return this._firestore.doc<User>(`${collection}/${uid}`).update(data);
    }
    /**
     * borra un enterprise
     * @param uid the uid to delete
     */
    delete(uid: string) {
        return this._firestore.doc(`${collection}/${uid}`).delete();
    }
}
